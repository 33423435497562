import analytics from '$/analytics';
import Logo from '$/assets/images/renew-logo.svg';
import InboxIcon from '@mui/icons-material/InboxOutlined';
import { Badge } from '@mui/material';
import { useRouter } from 'next/router';
import { memo } from 'react';
import HeartIcon from 'remixicon-react/HeartLineIcon';
import DollarIcon from 'remixicon-react/MoneyDollarCircleLineIcon';
import QuestionIcon from 'remixicon-react/QuestionLineIcon';

import IconLink from './IconLink';
import * as Styled from './styles';
import { Props } from './types';

function TopBarDesktop({
  className,
  openMenu,
  open = false,
  handleBack,
  text,
  noBottomShadow = false,
  residentTasksEnabled,
}: Props): JSX.Element {
  const { asPath: pathname } = useRouter();

  return (
    <>
      <Styled.Bar
        $noBottomShadow={noBottomShadow && !handleBack}
        className={className}
      >
        <Styled.Content>
          <Styled.Left>
            <Styled.LogoLink href="/" passHref>
              <Logo aria-label="Renew" />
            </Styled.LogoLink>
          </Styled.Left>

          <Styled.Center>
            <Styled.Link label="Home" href="/" isActive={pathname === '/'} />
            <Styled.Link
              label="Your renewal offers"
              href="/renewal-offer"
              isActive={pathname === '/renewal-offer'}
            />
            <Styled.Link
              label="Browse properties"
              href="/browse-properties"
              isActive={pathname.includes('/browse-properties')}
            />
          </Styled.Center>

          <Styled.Right>
            {residentTasksEnabled && (
              <IconLink
                icon={
                  <Badge
                    color="warning"
                    overlap="circular"
                    variant="dot"
                    badgeContent=" "
                  >
                    <InboxIcon />
                  </Badge>
                }
                href="/tasks"
                aria-label="Tasks"
                onClick={() =>
                  analytics?.track('app.nav-button.clicked', {
                    label: 'Tasks',
                  })
                }
                isActive={pathname === '/tasks'}
              />
            )}
            <IconLink
              icon={<QuestionIcon />}
              href="/help"
              isActive={pathname === '/help'}
            />
            <IconLink
              icon={<DollarIcon />}
              href="/rewards"
              isActive={pathname === '/rewards'}
            />
            <IconLink
              icon={<HeartIcon />}
              href="/favorites"
              isActive={pathname === '/favorites'}
            />
            <Styled.Button onClick={openMenu} $open={open}>
              <Styled.MenuIcon />
            </Styled.Button>
          </Styled.Right>
        </Styled.Content>
      </Styled.Bar>
      {handleBack && (
        <Styled.SubBar $noBottomShadow={noBottomShadow}>
          <Styled.Content>
            <Styled.Button onClick={handleBack}>
              <Styled.ArrowLeftIcon />
            </Styled.Button>
            <Styled.Center>
              <Styled.Text $size="lg">{text}</Styled.Text>
            </Styled.Center>
          </Styled.Content>
        </Styled.SubBar>
      )}
    </>
  );
}

export default memo(TopBarDesktop);
