import { gql, TypedDocumentNode } from '@apollo/client';

import { ImportantDeadlinesFragment } from '../generated';

const IMPORTANT_DEADLINES_FRAGMENT: TypedDocumentNode<ImportantDeadlinesFragment> = gql`
  fragment ImportantDeadlines on ImportantDeadlines {
    lastDayToRenew
    renewalSavingsEndOn
    leaseEndDate
  }
`;

export default IMPORTANT_DEADLINES_FRAGMENT;
