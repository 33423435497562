var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e34475583306f27fdf7e926086d6b5fa209c5be2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env['NEXT_PUBLIC_SENTRY_DSN'];

Sentry.init({
  dsn: SENTRY_DSN,
  // Set environment
  environment: process.env['NEXT_PUBLIC_ENV'],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // Only send transaction with traces if there's an error
  beforeSendTransaction: (transaction) => {
    const status = transaction?.contexts?.trace?.status ?? '';
    if (status && !['ok', 'unset'].includes(status)) {
      return transaction;
    }
    return null;
  },
  hideSourceMaps: false,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
